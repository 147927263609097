<template>
  <b-container fluid>
    <bo-page-title />
    <section>
        <bo-card title="Calculator Data" use-table v-if="isList">
            <b-card-body>
            <b-row>
                <b-col md="3">
                    <div class="input-group">
                        <datepicker  input-class="form-control transparent" :value="filter.startDate" placeholder="Start Date" @input="filter.startDate = fixDate($event),doFilterDate()"></datepicker>
                        <div class="input-group-append calendar-group">
                            <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                        </div>
                    </div>
                </b-col>
                
                <b-col md="3">
                    <div class="input-group">
                        <datepicker input-class="form-control transparent" :value="filter.endDate" placeholder="End Date"  @input="filter.endDate = fixDate($event),doFilterDate()" :disabledDates="{ to: new Date(filter.startDate) }"></datepicker>
                        <div class="input-group-append calendar-group">
                            <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                        </div>
                    </div>
                </b-col>
                
                <b-col md>
                </b-col>

                <b-col md="auto d-flex">

                <template v-if="(data.data||[]).length">
                    <b-dropdown right class="mr-2" variant="success" v-if="moduleRole('export')" dropbottom>
                    <template #button-content>
                        <i class="ti-upload mr-2"></i> Ekspor Data 
                    </template>
                    <b-dd-item-button @click="download('', {exptype: 'xlsx', tab: 'calculator-data'})">Sebagai .xls</b-dd-item-button>
                    <b-dd-item-button @click="download('', {exptype: 'pdf', tab: 'calculator-data'})">Sebagai .pdf</b-dd-item-button>
                    </b-dropdown>
                </template>
                <b-form @submit.prevent="doFilter()" class="d-flex">
                    <b-input-group>
                    <b-form-input v-model="filter.search" v-on:keyup.13="$emit('search')" placeholder="Type keyword then enter..."></b-form-input>
                    <b-input-group-append>
                        <b-button @click="doFilter()" variant="success"><i class="fas fa-search"></i></b-button>
                    </b-input-group-append>
                    </b-input-group>
                    <b-button
                        class="ml-1 d-inline-flex align-items-center"
                        variant="outline-success"
                        @click="doReset()"
                    >Reset</b-button>
                </b-form>
                </b-col>
            </b-row>
            </b-card-body>
            
            <b-table
            class="mb-custom-7"
            :fields="fields"
            :items="(data.data||[])"
            show-empty
            >
            <template #cell(num)="v">
                {{(data.per_page*(data.current_page-1))+v.index+1}}                
            </template>
            <template #cell(name)="data">
                <div>{{ data.item.arp_data.name || "-" }}</div>
            </template>
            <template #cell(email)="data">
                <div>{{ data.item.arp_data.email }}</div>
            </template>
            <template #cell(telp)="data">
                <div>{{ data.item.arp_data.telp || "-"}}</div>          
            </template>
            <template #cell(date)="data">
                <span v-if="data.item.arp_created_at">{{ data.item.arp_created_at | moment('LLL')}}</span>
                <span v-else>-</span>
            </template>
            <template #cell(actions)="data">
                <b-button
                variant="outline-info"
                class="btn-icon"
                v-b-tooltip.hover="'View Details'"
                :to="{ name: routeName, params: { pageSlug: data.item.arp_id } }"
                ><i class="icon-eye"></i></b-button>

                <a :href="uploaderAssets('calculator/'+data.item.arp_document+'.pdf')" download target="_blank">
                <b-button
                variant="outline-info"
                class="ml-2 btn-icon"
                v-b-tooltip.hover="'Download'"
                ><i class="ti-download"></i></b-button>
                </a>

            </template>
            <template #empty>
                <div class="text-center">
                <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
                <h4 align="center"><span v-if="Object.keys(filter).length">There is No Data</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
                </div>
            </template>
            </b-table>
            <template #footer>
                <b-pagination
                    v-if="data.total > data.per_page"
                    class="mb-0 justify-content-end"
                    v-model="pageNo"
                    :per-page="data.per_page"
                    :total-rows="data.total"
                />
            </template>
        </bo-card>
        <b-card v-else>
            <template #header>
                <div class="d-flex justify-content-between align-items-center">
                <div>
                    <h5 class="card-title">Calculation Result</h5>
                </div>
                <a :href="uploaderAssets('calculator/'+row.arp_document+'.pdf')" download target="_blank">
                    <b-button class="btn-rounded" variant="success">
                        View PDF
                        <i class="icon-docs ml-2"></i>
                    </b-button>
                </a>
                </div>
            </template>
            <b-row class="mb-3">
                <b-col md="4">
                    <div class="contact-info">
                        <strong class="contact-info__title">Name</strong>
                        <span class="contact-info__main">{{row.arp_data.name}}</span>
                    </div>
                </b-col>
                <b-col md="4">
                    <div class="contact-info">
                        <strong class="contact-info__title">Contacts</strong>
                        <a :href="'mailto:'+row.arp_data.email" class="contact-info__main">
                            <i class="ti-email"></i> {{row.arp_data.email}}</a>
                        <template v-if="row.arp_data.telp">
                            <a :href="'tel:'+row.arp_data.telp" class="contact-info__main">
                                <i class="icon-phone"></i> {{row.arp_data.telp||'-'}}
                            </a>
                        </template>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col md="4">
                    <div class="contact-info">
                        <strong class="contact-info__title">Vehicle Year</strong>
                        <span class="contact-info__main">{{row.arp_premi_request.years}}</span>
                    </div>
                </b-col>
                <b-col md="4">
                    <div class="contact-info">
                        <strong class="contact-info__title">Vehicle Age</strong>
                        <span class="contact-info__main">{{row.arp_premi_request.long_year}} Years</span>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col md="4">
                    <div class="contact-info">
                        <strong class="contact-info__title">Region Category</strong>
                        <span class="contact-info__main" v-if="row.arp_premi_result.code_plat">{{row.arp_premi_result.code_plat}} - Region - {{row.arp_premi_result.region}}</span>
                        <span class="contact-info__main" v-else>Region - {{row.arp_premi_result.region}}</span>
                    </div>
                </b-col>
                <b-col md="4">
                    <div class="contact-info">
                        <strong class="contact-info__title">Amount Insured</strong>
                        <span class="contact-info__main">Rp. {{formatDecimal(row.arp_premi_request.price)}},00-</span>
                    </div>
                </b-col>
            </b-row>
            <b-row class=" mt-4">
                <b-col md="10">
                <div class="contact-info">
                <strong class="contact-info__title">Primary Guarantee</strong>
                </div>
                <b-table-simple bordered striped small>
                    <thead>
                    <tr>
                        <th>Guarantee</th>
                        <th>Rate</th>
                        <th>Premium</th>
                    </tr>
                    </thead>
                    <b-tbody>
                        <b-tr>
                        <b-td>{{ row.arp_premi_request.type == 'C' ? 'Comprehensive' : 'Total Loss Only' }}</b-td>
                        <b-td>{{row.arp_premi_result.rate}}%
                        <span v-if="row.arp_premi_result.loadingValue"> + {{row.arp_premi_result.loadingValue}}%</span>
                        </b-td>
                        <b-td>Rp.{{formatDecimal(row.arp_premi_result.resultRate)}},00-</b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tbody>
                        <b-tr v-for="(v,k) in (row.arp_premi_result.rateAdd||[])" :key="k">
                        <b-td>{{v.label}}</b-td>
                        <b-td>{{v.rate}}%</b-td>
                        <b-td>Rp.{{formatDecimal(v.price)}},00-</b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tfoot>
                        <b-tr>
                        <b-td colspan="2">
                            <div class="text-right">
                            <strong>Total Main Cover:</strong>
                            </div>
                        </b-td>
                        <b-td>
                            <strong>Rp. {{formatDecimal(row.arp_premi_result.mainPremium)}},00-</strong>
                        </b-td>
                        </b-tr>
                    </b-tfoot>
                </b-table-simple>
                </b-col>
            </b-row>
            <b-row class="" v-if="row.arp_premi_request.thirdParty">
                <b-col md="10">
                <div class="contact-info">
                <strong class="contact-info__title">Third Party</strong>
                </div>
                    <b-table-simple bordered small striped>
                        <thead>
                        <tr>
                            <th>Guarantee</th>
                            <th>Limit</th>
                            <th>Rate</th>
                            <th>Premium</th>
                        </tr>
                        </thead>
                        <b-tbody v-if="row.arp_premi_result.tpRates">
                            <b-tr>
                            <b-td class="text-lowercase">legal responsibility against 3rd party</b-td>
                            <b-td>Rp. {{formatDecimal(row.arp_premi_request.datathirdParty.price)}},00-</b-td>
                            <b-td>-</b-td>
                            <b-td>Rp. {{formatDecimal(row.arp_premi_result.hasilTP.totalThirdParty)}},00-</b-td>
                            </b-tr>

                            <b-tr>
                            <b-td class="text-lowercase">personal accident for drivers</b-td>
                            <b-td>Rp. {{formatDecimal(row.arp_premi_request.datathirdParty.price_driver)}},00-</b-td>
                            <b-td>{{row.arp_premi_result.dRate}}%</b-td>
                            <b-td>Rp. {{formatDecimal(row.arp_premi_result.hasilTP.driverValue)}},00-</b-td>
                            </b-tr>
                            <b-tr>
                            
                            <b-td class="text-lowercase">personal accident for passenger</b-td>
                            <b-td>Rp. {{formatDecimal(row.arp_premi_request.datathirdParty.price_passanger)}},00-</b-td>
                            <b-td>{{row.arp_premi_result.hasilTP.ratePassanger}}% ({{row.arp_premi_result.pRate}} x {{row.arp_premi_request.datathirdParty.jml_penumpang}})</b-td>
                            <b-td>Rp. {{formatDecimal(row.arp_premi_result.hasilTP.passangerValue)}},00-</b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tfoot v-if="row.arp_premi_result.hasilTP">
                            <b-tr>
                            <b-td colspan="3">
                                <div class="text-right">
                                <strong>Total Extension Cover:</strong>
                                </div>
                            </b-td>
                            <b-td>
                                <strong>Rp. {{formatDecimal(row.arp_premi_result.hasilTP.resultExtension)}},00-</strong>
                            </b-td>
                            </b-tr>
                        </b-tfoot>
                    </b-table-simple>   
                </b-col>
            </b-row>
            <b-row class="">
                <b-col md="10">
                    <div class="main-card">
                        <div class="main-card-body">
                            <b-row align-v="center">
                            <b-col md="6">
                                <div class="final-premi-count">
                                <span>Premium (Primary Guarantee + Third Party)</span><br/>
                                <strong>Rp. {{formatDecimal(row.arp_premi_result.hasilAkhir)}},00-</strong>
                                </div>
                                <div class="final-premi-count">
                                <span>Service Fee and Stamp</span><br/>
                                <strong>Rp. {{formatDecimal(row.arp_premi_result.stamp)}},00-</strong>
                                </div>
                            </b-col>
                            <b-col md="6" class="border-left">
                                <div class="premi-total-count">
                                <span>Total Premium</span><br/>
                                <strong>Rp. {{formatDecimal(row.arp_premi_result.finalPremi)}},00-</strong>
                                </div>
                            </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>            
            </b-row>
            <template #footer="">
                <div class="text-right">
                    <b-button :to="{name: routeName}" variant="info" class="btn-rounded">Back
                    </b-button>
                </div>
            </template>
        </b-card>        
    </section>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'
// import Gen from '@/libs/Gen.js'
// import $ from 'jquery'
// import _ from 'lodash'
import _ from 'lodash'
const moment = require('moment')


export default {
  extends: GlobalVue,
  components: {
    Datepicker
  },
  data() {
    return {
        currentPage: 1,
        fields: [
        { key: 'num', label: '#' },
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' }, 
        { key: 'telp', label: 'Phone' },
        { key: 'date', label: 'Generate Date' }, 
        'actions'
        ],
    }
  },
  mounted() {
    this.apiGet()
  },
  computed: {
    pageNo: {
      // getter
      get:function() {
        return this.filter.page||this.$route.query.page||1
      },
      // setter
      set:function(newValue) {
        this.$set(this.filter, "shown", 50)
        this.$set(this.filter, "page", newValue)
        this.$router.push({
          name:this.modulePage,
          query:_.clone(this.filter)
        }).catch(()=>{})
      }
    },

    perPage:{
      // getter
      get:function() {
        return this.$route.query.shown||this.filter.shown||50
      },
      // setter
      set:function(newValue) {
        this.pageNo=1
        this.$router.push({
          name:this.modulePage,
          query:{shown:newValue}
        }).catch(()=>{})
      }
    },
  },
  methods: {
    fixDate(event){
        return moment(event).format('YYYY-MM-DD')
    },
    formatDecimal(data) {
      return new Intl.NumberFormat('id-ID', ({ style: 'decimal' })).format(data)
    },
    doFilterDate(){
        if(this.filter.startDate && this.filter.endDate){
            this.doFilter()
        }
    }
  },
  watch:{
    $route(){
    this.apiGet()
    },
  }
}
</script>